@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.uploadContainer {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  height: calc(100vh - 144px);
  min-height: 540px;
  position: relative;

  @include mqMaxWidth($screenS) {
    height: calc(100vh - 182px);
  }

  &.isAllocateApp {
    height: calc(100vh - 99px);

    @include mqMaxWidth($screenS) {
      height: calc(100vh - 133px);
    }

    @include mqMaxWidth($screenXs) {
      height: calc(100vh - 123px);
    }
  }
}

.uploadContent {
  height: calc(100% - 56px);
}

.childrenContainer {
  height: 100%;
}

.header {
  height: 56px;
  background-color: $secondary5Color;
  position: relative;

  &.scrollable {
    .menuWrapper {
      overflow: scroll;
      box-sizing: border-box;
      padding: 0 16px;
      justify-content: flex-start;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 100%;
      background: linear-gradient(90deg, rgba(249, 249, 249, 1) 0, rgba(249, 249, 249, 0) 100%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 100%;
      background: linear-gradient(270deg, rgba(249, 249, 249, 1) 0, rgba(249, 249, 249, 0) 100%);
    }
  }
}

.menuWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerMenu {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.step {
  display: flex;
  margin-right: 20px;
  align-items: center;

  .circle {
    width: 16px;
    height: 16px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    background-color: $titleColor;
    border-radius: 100%;
    font-weight: 400;
    letter-spacing: -0.5px;
    opacity: 0.2;
    cursor: default;
  }

  .title {
    color: $titleColor;
    letter-spacing: 0.05em;
    line-height: 16px;
    font-size: 10px;
    font-weight: 500;
    opacity: 0.2;
    margin-left: 10px;
    text-transform: uppercase;
  }

  .dash {
    width: 10px;
    height: 1px;
    background-color: $secondary2Color;
    margin-left: 20px;
  }

  &.isActive {
    .circle,
    .title {
      opacity: 1;
    }
  }

  &.hiddenTitle {
    margin-right: 10px;

    .circle {
      &:hover {
        opacity: 1;
      }
    }

    .title {
      display: none;
    }

    .dash {
      margin-left: 10px;
    }

    &.isActive {
      .title {
        display: block;
      }
    }
  }

  &.isDisabled {
    .circle,
    .title {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &:last-child {
    margin-right: 0;

    .dash {
      display: none;
    }
  }
}

.stepTooltip {
  text-transform: capitalize;
}

.modal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.isTransparent {
  opacity: 0;
}

.footer {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
