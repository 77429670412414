@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.title {
  font-size: 20px;
  color: $titleColor;
  box-sizing: border-box;
  line-height: 23px;
  padding: 20px;

  @include mqMaxWidth($screenXs) {
    padding: 20px 10px;
  }
}

.form {
  width: 100%;
  margin: 0 auto;
  height: calc(100% - 63px);
  position: relative;
}

.scrollContainer {
  height: calc(100% - 76px);
  overflow-y: scroll;
  width: 100%;
  padding: 0 10px;

  &.modifyDisabled {
    @include mqMaxWidth($screenS) {
      height: calc(100% - 150px);
    }
  }
}

.formWrapper {
  height: 100%;
  width: 100%;
  max-width: 495px;
  margin: 0 auto;
  padding-top: 40px;

  @include mqMaxWidth($screenS) {
    padding-top: 10px;
  }
}

.formInputWrapper {
  width: 100%;
  max-width: 495px;
}

.enhancedTaggingWrapper {
  > div:first-child {
    padding: 0;

    @include mqMaxWidth($screenS) {
      margin-bottom: 30px;
    }
  }
}

.underwritersWrapper {
  padding-bottom: 40px;

  > div:first-child {
    padding: 0;

    > div:first-child {
      @include mqMaxWidth($screenS) {
        padding: 0;
      }
    }

    > div:last-child {
      flex-basis: 56px;
      flex-shrink: 0;
      text-align: center;
      margin-right: 36px;
    }
  }

  > button {
    padding-left: 0;

    &:disabled {
      padding-left: 0;
    }

    > div {
      padding-left: 0;
    }
  }

  &.disabled {
    > div:first-child {
      padding: 0;

      > div:last-child {
        margin-right: 0;
      }
    }
  }

  @include mqMaxWidth($screenS) {
    padding-bottom: 10px;
  }
}

.underwritersRow {
  background: none;
  border: 0;

  &:hover {
    background: none;
  }

  &:first-child {
    border: 0;
  }

  > div {
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }

    &:nth-child(2) {
      flex-shrink: 0;
    }
  }

  button {
    margin-right: 0;
    flex-shrink: 0;
  }
}

.buttons {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modifyButton {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.cancelButton {
  @include mqMaxWidth($screenXs) {
    color: $cancelColor;

    &:hover {
      color: $cancelColorHover;
    }
  }
}

.modifyMobile {
  display: none;
  position: absolute;
  bottom: 76px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 515px;
  padding: 0 10px;
  margin: auto;

  p {
    margin: 0 auto;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $secondaryColor;
    text-align: center;
  }

  button {
    margin-bottom: 10px;
    display: block;
    width: 100%;
  }

  @include mqMaxWidth($screenS) {
    display: block;
  }
}
