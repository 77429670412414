@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.modal {
  :global {
    .modalDialog {
      max-width: 600px;
    }
  }
}

.smallModal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}

.modalInputs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;

  @include mqMaxWidth($screen568) {
    flex-direction: column;
    align-items: center;
  }
}

.inputRefreshIcon {
  fill: $secondaryColor;
  margin-top: 30px;

  @include mqMaxWidth($screen568) {
    margin: 20px 0;
  }
}

.modalInputWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  width: 252px;

  .label {
    color: $secondary3Color;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 0.1em;
  }

  .inputField {
    width: 252px;
  }

  @include mqMaxWidth($screen568) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .label {
      margin-bottom: 5px;
    }

    .inputField {
      width: 100%;
    }
  }
}
