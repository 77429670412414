@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.modalOverlay {
  .modal {
    max-width: 450px;
  }
}

.form {
  height: 100%;
  width: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mqMaxHeight(850px) {
    display: block;
    overflow-y: scroll;
    padding: 40px 0 86px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
}

.icon {
  margin-bottom: 20px;
  min-height: 55px;
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: $titleColor;
  margin-bottom: 10px;
  text-align: center;

  @include mqMaxWidth($screenS) {
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $baseTextColor;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 30px;
  box-sizing: border-box;

  @include mqMaxWidth($screen640) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}

.fieldContainer {
  max-width: 500px;
  width: 100%;
  position: relative;
}

.buttons {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.importButton {
  @include mqMaxWidth($screenXs) {
    margin-right: 0;
  }
}
