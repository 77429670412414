@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.container {
  display: flex;
  align-items: center;
}

.inputWrapper {
  width: 284px;
  margin-right: 20px;
  position: relative;
}

.suggestionsCount {
  color: $secondaryColor;
  font-size: 12px;
}

.checkIcon {
  path {
    fill: $successColor;
  }
}

.input {
  &.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $secondary3Color;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }

  &::placeholder {
    color: $secondaryColor;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -1px;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #999;
  display: block;
  max-height: 139px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1;
  padding-bottom: 31px;
}

.scrollWrapper {
  padding-top: 6px;
  max-height: 106px;
  overflow: scroll;
}

.option {
  text-align: left;
  background-color: #fff;
  font-size: 14px;
  color: $titleColor;
  line-height: 25px;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: $defaultBackgroundColor;
  }
}

.addAccountOption {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 31px;
  background-color: #fff;
  width: 100%;
  line-height: 25px;
  box-sizing: border-box;
  font-size: 14px;
  color: $actionColor;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    color: $actionColorHover;
  }
}
