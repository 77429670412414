.button {
  margin-right: 0;
  width: 36px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: currentColor;
  }
}
