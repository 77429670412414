@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.titleQuestionIcon {
  margin-left: 4px;
  position: relative;
  width: 12px;
  height: 12px;
  top: 2px;
  fill: $secondaryColor;
}

.twoColumnsLayout {
  display: flex;
  width: 100%;

  @include mqMaxWidth($screenM) {
    display: block;
  }
}

.twoColumnsLayoutLeft {
  min-width: 400px;
  padding-right: 10px;
  position: relative;

  @include mqMaxWidth($screenM) {
    display: block;
    width: 100%;
    padding-right: 0;
    min-width: auto;
  }
}
.sliderButtonWrp {
  .sliderButton {
    svg {
      position: absolute;
      top: 50%;
      bottom: 50%;
      left: 2.5px;
    }
  }
}

.twoColumnsLayoutRight {
  width: 100%;
  padding-left: 10px;
  min-width: 510px;

  @include mqMaxWidth($screenM) {
    display: block;
    width: 100%;
    padding-left: 0;
    min-width: auto;
  }
}

.pageHeader {
  min-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: none !important;
  }
}

.pageHeaderTitle,
.pageHeaderActions {
  float: none;
  padding-top: 0;
}

.pageHeaderTitle {
  margin-top: -3px;
}

.boldCell {
  font-weight: 500;
}

.cardHeader {
  @include mqMaxWidth($screenXs) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 23px;
    width: 100%;
  }
}

.cardHeaderTitle {
  @include mqMaxWidth($screenXs) {
    display: block;
    float: none;
    width: auto;
    text-align: left;
    margin-bottom: 0;
  }
}

.cardHeaderActions {
  @include mqMaxWidth($screenXs) {
    display: block;
    float: none;
    width: auto;
    text-align: right;
    margin-bottom: 0;
  }
}

.dataroomUniqueLoginCountTooltip {
  max-width: 206px;
}

.disabledNavLink {
  pointer-events: none;
  color: $secondaryColor;
}

.teamsTooltip {
  margin-left: 35px;
}

.teamsLink {
  display: inline-flex;
  align-items: center;

  &.disabledNavLink {
    .betaLabel {
      background: $secondary2Color;
    }
  }
}

.betaLabel {
  margin-left: 5px;
  font-size: 10px;
  line-height: 10px;
  border-radius: 2px;
  padding: 1.5px 3px;
  font-weight: 700;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(213deg, $firmColor2 0%, $firmColor 100%);
}

.responsiveCell {
  max-width: 0;
  word-break: break-word;
}

.spinnerWrp {
  padding: 30px 0;
}
