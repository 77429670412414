@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.disabledNavLink {
  pointer-events: none;
  color: $secondaryColor;
}

.mediumWeight {
  font-weight: 500;
}
