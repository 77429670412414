@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$managerItemHoverColor: #ebebeb;

.table {
  height: calc(100% - 55px);
}

.title {
  line-height: 25px;
  font-size: 20px;
  font-weight: normal;
  color: $titleColor;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 10px;

  @include mqMaxWidth($screenXs) {
    padding-left: 10px;
  }
}

.form {
  height: calc(100% - 76px);
}

.horizontalScrollWrapper {
  margin-bottom: 20px;
  overflow-x: scroll;
  max-height: calc(100% - 40px);

  &.disabled {
    max-height: 100%;

    .tableHeader {
      .rightSection {
        text-align: right;
      }
    }

    .managerCheckbox {
      width: 16px;
    }

    @include mqMaxWidth($screenXs) {
      max-height: calc(100% - 94px);
    }
  }
}

.scrollContainer {
  width: 100%;
  height: 100%;
  min-width: 612px;
}

.tableHeader {
  width: 100%;
  font-weight: 500;
  line-height: 16px;
  padding: 15px 36px 15px 20px;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $secondary3Color;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;

  > div {
    width: 276px;
    flex-shrink: 0;
  }

  .rightSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    flex-shrink: 0;

    > div {
      width: 140px;
      flex-shrink: 0;
    }
  }
}

.managerList {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100% - 46px);
}

.managerItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $secondary5Color;
  padding: 4px 20px;

  &:hover {
    background: $managerItemHoverColor;

    .action {
      opacity: 1;
    }
  }

  &.disabled {
    padding-right: 36px;

    &:hover {
      background: $secondary5Color;
    }
  }

  > div:first-child {
    margin-bottom: 0;
  }

  .managerInput {
    width: 276px;

    input {
      width: 276px;
    }
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  .managerCheckbox {
    width: 140px;

    .managerCheckboxControl {
      margin: 0 auto;
      display: block;
    }
  }

  .action {
    cursor: pointer;
    fill: $actionColor;
    opacity: 0;

    &:hover {
      fill: $actionColorHover;
    }
  }
}

.buttons {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveButton {
  @include mqMaxWidth($screenXs) {
    margin-right: 0;
  }
}

.backButton {
  @include mqMaxWidth($screenXs) {
    padding: 0 5px 0 10px;
    margin: 0;
  }
}

.cancelButton {
  @include mqMaxWidth($screenXs) {
    color: $cancelColor;
    padding: 0 10px;

    &:hover {
      color: $cancelColorHover;
    }
  }
}

.modifyButton {
  @include mqMaxWidth($screenXs) {
    display: none;
  }
}

.modifyMobileButton {
  display: none;
  width: calc(100% - 20px);
  margin: 0 auto 10px;

  @include mqMaxWidth($screenXs) {
    display: block;
  }
}

.modifyMessage {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $secondaryColor;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  display: none;

  @include mqMaxWidth($screenXs) {
    display: block;
  }
}

.dealManagersButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.addDealManagersForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}
