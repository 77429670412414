@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

tbody {
  tr {
    &:hover {
      .actionButtonsSection {
        display: flex;
      }
    }

    td {
      white-space: nowrap;
    }
  }
}

.statusIndicator {
  float: none;
  display: inline-block;
}

.ratingCell {
  vertical-align: middle;

  &.fixedWidth {
    max-width: 117px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.ratingTooltipItem {
  display: flex;
  justify-content: space-between;

  span {
    opacity: 0.6;
    margin-right: 15px;
  }
}

.leisTooltip {
  text-align: left;
}

.bookrunnersCell {
  max-width: 0;
  vertical-align: top;
}

.responsiveCellContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  vertical-align: middle;
}

.responsiveCell {
  max-width: 0;
}

.upsizeCell {
  position: relative;
}

.upsizeCellIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -14px;

  i {
    width: 10px;
    height: 10px;
    position: relative;
    top: -2px;
  }
}

.greyText {
  color: $secondaryColor;
}

.roadshowLinkIcon {
   i {
     width: 14px;
     height: 14px;
   }
}

.actionButtonsSection {
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  top: 0;
  width: 106px;
  height: 100%;
  background: $ColorGrayscaleGray5;
}

.actionButtonsDesktop {
  background: none;
  display: flex;
  flex-direction: row;

  button,
  a {
    display: block;
    background: none;
    padding: 0;
    margin-left: 20px;

    &:hover {
      background: none;
    }

    .actionTooltip {
      padding: 0;
    }

    .actionButtonIcon {
      margin-right: 0;

      i {
        width: 16px;
        height: 16px;
      }
    }

    .actionButtonText {
      display: none;
    }
  }
}

.upsizedIcon {
  fill: $successColor;
}
