@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$tenantVeriSendColor: #92929d;
$tenantResearchRoomColor: #646474;

.consentWrp {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: relative;
  min-height: calc(100vh - 145px);

  @include mqMaxWidth($screenM) {
    min-height: auto;
  }
  overflow: hidden;
}

.header {
  height: 190px;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    transform: skewY(-5.8deg);

    height: 296px;
    top: -185px;
    background: $tenantVeriSendColor;

    .tenantresearchroom & {
      background: $tenantResearchRoomColor;
    }
  }

  @include mqMaxWidth(1024px) {
    margin-bottom: 0;
  }

  z-index: 2;
  position: relative;

  h2,
  h3 {
    margin-left: 50px;
    color: #fff;
    position: relative;
    z-index: 2;
  }

  h2 {
    margin-top: 34px;
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 17px;
    font-family: "Open Sans", sans-serif;
  }

  h3 {
    font-size: 18px;
    line-height: 18px;
    font-family: Roboto, sans-serif;
  }

}

.contentWrp {
  text-align: center;
  width: 100%;
  display: table;
  vertical-align: middle;
  height: calc(100vh - 280px);

  @include mqMaxWidth($screenM) {
    padding: 50px 15px;
    height: auto;
  }

  p {
    color: #616161;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;

    @include mqMaxWidth($screenL) {
      max-width: 542px;
    }

  }
}

.content {
  display: table-cell;
  vertical-align: middle;

  p {
    margin: 16px auto;
  }
}

.agreeButton {
  margin-top: 15px;
  width: 300px;
}

.learnMoreLink {
  margin-top: 24px;
  display: inline-block;
}
