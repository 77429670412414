@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$sidebarWidth: 700px;

.exportToExcelLink {
  &:hover {
    .exportToExcelIcon {
      fill: $actionColorHover;
    }
    .exportToExcelLabel {
      color: $actionColorHover;
    }
  }
}

.exportToExcelLabel {
  color: $actionColor;
  @include mqMaxWidth(1015px) {
    display: none;
  }
}

.exportToExcelIcon {
  fill: $actionColor;
  display: inline-block;
  position: relative;
  top: 2px;

  @include mqMaxWidth(1015px) {
    margin-right: 0;
  }
}

.exportToExcelSpinner {
  display: inline-block;
  position: relative;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 6px;
}

.filterActionBtn {
  margin-left: 20px;
  position: relative;
  top: -2px;
}

.twoColumnsLayout {
  display: table;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  @include mqMaxWidth($screenS) {
    display: block;
  }

  &.withMap {

    @include mqMaxWidth($screenM) {
      display: block;
    }

    .twoColumnsLayoutLeft {
      @include mqMaxWidth($screenM) {
        display: block;
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
        height: auto;
      }
    }

    .twoColumnsLayoutRight {
      @include mqMaxWidth($screenM) {
        display: block;
        width: 100%;
        padding-left: 0;
        height: auto;
      }
    }
  }
}

.twoColumnsLayoutLeft {
  display: table-cell;
  width: 50%;
  padding-right: 10px;
  height: 100%;

  @include mqMaxWidth($screenS) {
    display: block;
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
    height: auto;
  }
}

.twoColumnsLayoutRight {
  display: table-cell;
  width: 50%;
  padding-left: 10px;
  height: 100%;

  @include mqMaxWidth($screenS) {
    display: block;
    width: 100%;
    padding-left: 0;
    height: auto;
  }
}

.statsHeader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.statsHeaderWithFilter {
  margin-bottom: 30px;
}

.statsFilter {
  @include mqMaxWidth($screenXs) {
    margin-bottom: 0;
  }
}

.statsCardInner {
  padding-bottom: 0;
}

.statsHeaderTitle {
  display: block;
  margin-right: 10px;
  margin-bottom: 30px;

  @include mqMaxWidth($screenXs) {
    float: none;
    width: auto;
  }
}

.statsHeaderActions {
  display: block;
  margin-bottom: 30px;

  @include mqMaxWidth($screenXs) {
    float: none;
    width: auto;
    text-align: left;
  }
}

.statsTable {
  margin-top: -12px;
}

.navLink {
  margin-right: 35px !important;
}

.navLinkIsDisable {
  margin-right: 35px;
  color: $placeholderColor;

  &:last-child {
    margin-right: 0;
  }
}

