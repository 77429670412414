@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.uploadExcelWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon {
    margin-bottom: 30px;

    @include mqMaxWidth($screenS) {
      margin-bottom: 20px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    color: $titleColor;
    margin-bottom: 20px;
    text-align: center;

    @include mqMaxWidth($screenS) {
      font-size: 30px;
      line-height: 33px;
      margin-bottom: 10px;
    }
  }

  .description {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $baseTextColor;
    width: 100%;
    max-width: 650px;
    margin: 0 auto 30px;
    box-sizing: border-box;

    @include mqMaxWidth($screenS) {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 20px;
      padding: 0 13px;
    }
  }

  .downloadTemplate {
    color: $actionColor;
    display: flex;
    align-items: center;
    margin: 30px auto 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;

    .downloadTemplateIcon {
      margin-right: 10px;

      path {
        fill: $actionColor;
      }
    }

    &:last-child {
      margin-top: 0;
      margin-bottom: 0;
    }

    &:hover {
      color: $actionColorHover;

      .downloadTemplateIcon {
        path {
          fill: $actionColorHover;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      color: #dadada;

      .downloadTemplateIcon {
        path {
          fill: #dadada;
        }
      }
    }

    @include mqMaxWidth($screenS) {
      margin: 20px auto;
    }
  }
}

.errors {
  height: 100%;
  width: 100%;
  position: relative;
}

.tableHeader {
  height: 44px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 16px;

  .title {
    line-height: 20px;
    font-size: 20px;
    color: $titleColor;
    font-weight: normal;
    text-align: left;
  }

  @include mqMaxWidth($screen568) {
    padding-left: 11px;
  }
}

.tableContainer {
  height: calc(100% - 120px);
  overflow: auto;

  table {
    min-width: 560px;
    background: #fff;

    tbody {
      background: #fff;

      tr td {
        &.errorCell {
          color: $cancelColor;
        }
      }
    }
  }
}

.tableFooter {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  height: 76px;
  display: flex;
  align-items: center;
}

.reuploadBtn {
  margin-right: 30px;

  @include mqMaxWidth($screen568) {
    margin-right: 10px;
  }
}

.modal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}
