@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tableHeader {
  height: 44px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 20px;

  .title {
    line-height: 20px;
    font-size: 20px;
    color: $titleColor;
    font-weight: normal;
  }
}

.horizontalScrollContainer {
  height: calc(100% - 120px);
  width: 100%;
  overflow-x: auto;
}

.tablesScrollWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: fit-content;
}

.generalInfo {
  padding-bottom: 15px;
  padding-top: 20px;
  flex: 0 1 auto;

  thead {
    tr {
      border: 0;

      th {
        border: 0;
        padding-top: 0;
        padding-bottom: 10px;
        line-height: 12px;
      }
    }
  }

  tbody {
    tr {
      border: 0;

      td {
        color: $titleColor;
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }
}

.tableContainer {
  flex: 1 1 auto;

  table {
    height: 100%;
    background-color: #fff;

    tbody {
      background-color: #fff;

      td {
        color: $titleColor;
        white-space: nowrap;
      }
    }
  }
}

.modal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}
