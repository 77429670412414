.row {
  display: flex;
  flex-direction: row;

  .appLabel {
    min-width: 31px;
    padding-right: 10px;
  }
}

.select {
  width: 100%;
  margin-bottom: 10px;
}

.dealsAppFilter {
  margin-bottom: 10px;
}

.labelWrapper {
  position: relative;
  display: table;
}

.title {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: table-cell;
}
