@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }

  25% {
    background-position: 25% 50%;
  }

  50% {
    background-position: 50% 50%;
  }

  75% {
    background-position: 75% 50%;
  }

  100% {
    background-position: 100% 50%;
  }

}

@keyframes Blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

.animatedPreloader {
  font-size: 0;
  padding: 12px 20px;
  max-width: 200px;
  box-sizing: border-box;
  height: 38px;
}

.animatedSquare,
.animatedRectangle {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  //animation-delay: 1s !important;
  border-radius: 3px;
}

.animatedSquare {
  width: 16px;
  margin-right: 8px;
  animation-duration: 1s;
  animation-delay: 2s !important;
  background-color: rgba(0, 0, 0, 0.22);
}

.animatedRectangle {
  width: calc(100% - 24px);
  //background: linear-gradient(-90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.65));
  //background: linear-gradient(-90deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.35));
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.22));
  background-size: 200% 200%;
  animation: Gradient 0.5s ease infinite;
  animation-direction: alternate-reverse;
  //animation-delay: 2s !important;
}
