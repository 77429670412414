@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tableHeader {
  height: 56px;
  box-sizing: border-box;
  padding: 20px 20px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  @include mqMaxWidth($screenS) {
    height: auto;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.title {
  line-height: 24px;
  font-size: 20px;
  color: $titleColor;

  @include mqMaxWidth($screenS) {
    margin-bottom: 18px;
    line-height: 20px;
  }
}

.searchInput {
  width: 320px;

  &.withSelect {
    @include mqMaxWidth(890px) {
     width: 240px;
    }

    @include mqMaxWidth($screenS) {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.tableFilters {
  display: flex;

  @include mqMaxWidth($screenS) {
    flex-direction: column-reverse;
  }
}

.tabsWrapper {
  display: block;
  height: 36px;
  padding-left: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &.isHidden {
    height: 0;
  }
}

.tabsWideContainer {
  width: 200vw;
}

.selectWrapper {
  width: 158px;
  height: 36px;
  margin-left: 10px;
  position: relative;
  z-index: 2;

  @include mqMaxWidth(890px) {
    width: 128px;
  }

  @include mqMaxWidth($screenS) {
    width: 100%;
    margin-left: 0;
  }
}

.select {
  height: 36px;
  width: 100%;
}

.horizontalScrollContainer {
  height: calc(100% - 175px);
  width: 100%;
  overflow: auto;
  border-top: 1px solid $secondary2Color;
  transform: translateY(-1px);

  &.withoutTabs {
    height: calc(100% - 140px);
    transform: translateY(0);
    border: 0;

    @include mqMaxWidth($screenS) {
      height: calc(100% - 216px);
    }
  }
}

.tableContainer {
  height: 100% !important;

  table {
    height: 100%;
    background-color: #fff;

    tbody {
      background-color: #fff;

      tr {
        td {
          color: $titleColor;
          white-space: nowrap;
        }

        &:hover {
          background-color: #ebebeb !important;
        }

        &:only-child {
          &:hover {
            background-color: $secondary5Color !important;
          }
        }
      }
    }
  }
}
