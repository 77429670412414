@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.form {
  height: 100%;
  width: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 76px;

  @include mqMaxHeight(850px) {
    display: block;
    overflow-y: scroll;
    padding: 40px 0 86px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
}

.icon {
  margin-bottom: 30px;
  min-height: 55px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 20px;
  }
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: $titleColor;
  margin-bottom: 20px;
  text-align: center;

  @include mqMaxWidth($screenS) {
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $baseTextColor;
  width: 100%;
  max-width: 530px;
  margin: 0 auto 30px;
  box-sizing: border-box;

  @include mqMaxWidth($screen640) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 20px;
    padding: 0 10px;
  }
}

.field {
  @include mqMaxWidth($screen568) {
    max-width: 92%;
  }
}

.fieldContainer {
  max-width: 495px;
  width: 100%;
  position: relative;
}

.checkIcon {
  position: absolute;
  fill: $successColor;
  right: -36px;
  top: 29px;

  @include mqMaxWidth($screen568) {
    right: 0;
  }
}

.linkIcon {
  width: 16px;
  height: 16px;
  fill: $secondaryColor;
  margin-bottom: 30px;
}

.warningWrapper {
  max-width: 515px;
  width: 100%;
  padding: 0 10px;

  @include mqMaxWidth($screen568) {
    padding: 0;
  }
}

.warning {
  padding: 10px;
  background: $backgroundHighlightColor;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: $secondary3Color;
  fill: $secondary3Color;
  display: flex;
  flex-direction: row;
  margin: 0;

  span:first-child {
    margin-right: 10px;
  }
}

.buttons {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  height: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveButton {
  @include mqMaxWidth($screenXs) {
    margin-right: 0;
  }
}
