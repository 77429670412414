@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.modal {
  :global {
    .modalDialog {
      width: 100%;
      max-width: 600px;
    }
  }
}

.formContainer {
  border-left: 1px solid $secondary2Color;

  .step {
    position: relative;
    margin-bottom: 40px;
    padding-left: 30px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-50%);
      width: 17px;
      height: 17px;
      background-color: $secondary2Color;
      border: 5px solid #fff;
      z-index: 2;
      border-radius: 50%;
    }

    &.activeStep {

      &:before {
        background-color: $secondaryColorHover;
      }
    }

    .titleStep {
      color: #404040;
      font-family: $fontFamily;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 20px;
    }
  }

  .dealManagersFieldWrapper {
    .errorMessagesWrapper {
      margin-top: 5px;
      position: relative;

      .errorMessage {
        color: $cancelColor;
        font-size: 11px;
        line-height: 15px;
        display: none;
        position: absolute;
        top: 0;
        left: 0;

        &.show {
          display: block;

          + .errorMessage {
            top: 15px;
          }
        }
      }
    }
  }
}

.fieldRow {
  + .fieldRow {
    margin-top: 15px;
  }

  &.selectRow {
    margin-left: 26px;
    margin-top: 10px;
  }
}

