@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.uploadBtn {
  display: inline-block;
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  border: 1px solid transparent;
  appearance: none;
  text-decoration: none;
  padding: 0 21px;
  background-color: $actionColor;
  color: #fff;
  text-align: center;
  font-family: $fontFamily;
  letter-spacing: 0.02em;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  input {
    display: none;
  }

  &:hover {
    background-color: $actionColorHover;
  }

  &:active {
    background-color: darken($actionColorHover, 2%);
  }

  &:focus {
    outline: 0;
  }

  &.disabled {
    cursor: default;
    background-color: $secondary2Color;
    color: #fff;

    &:hover {
      background-color: $secondary2Color;
    }
  }
}

.uploadBtnTooltip {
  margin-top: 5px;
}

.modal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}
