@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.inputWrapper {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.label {
  display: inline-block;
  line-height: 10px;
  position: relative;
}

.questionIcon {
  position: absolute;
  top: -2px;
  right: -16px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;
    position: relative;
    top: 1px;

    svg {
      vertical-align: top;
    }

    &:hover {
      fill: $titleColor;
    }
  }
}

.openedInput {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: $secondary3Color;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -1px;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #999;
  display: block;
  max-height: 139px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1;

  @include mqMaxWidth($screen568) {
    width: 92%;
  }
}

.option {
  text-align: left;
  background-color: #fff;
  font-size: 14px;
  color: $titleColor;
  line-height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 12px;

  &:hover {
    background-color: $defaultBackgroundColor;
  }

  &.disabledOption {
    color: $secondaryColor;
    cursor: default;
    pointer-events: none;
  }
}

.zeroCase {
  text-align: left;
  background-color: #fff;
  font-size: 14px;
  color: $secondaryColor;
  line-height: 32px;
  box-sizing: border-box;
  padding-left: 10px;
  pointer-events: none;
}
