.bookrunnersCell {
  max-width: 0;
  vertical-align: top;
}

.bookrunnersCellContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  vertical-align: middle;

  &.multiLined {
    white-space: normal;
    -webkit-box-orient: vertical;
  }
}

.securityCell {
  display: flex;
  align-items: center;
}

.label {
  min-width: 42px;
  margin-left: 12px;
  transform: translateY(-1px);
}

.totalProceedsTooltipItem {
  display: flex;
  justify-content: space-between;

  span {
    opacity: 0.6;
    margin-right: 10px;
  }
}
