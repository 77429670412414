.labelWrapper {
  position: relative;
  display: table;
}

.title {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: table-cell;
}

.disabled {
  color: #ccc;
}
