@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.linkAccountsWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.tableHeader {
  height: 44px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-top: 20px;

  .title {
    line-height: 20px;
    font-size: 20px;
    color: $titleColor;
    font-weight: normal;
  }

  .titleHint {
    margin-left: 10px;

    i {
      width: 12px;
      height: 12px;
      fill: $secondaryColor;

      &:hover {
        fill: $titleColor;
      }
    }
  }
}

.horizontalScrollContainer {
  height: calc(100% - 120px);
  width: 100%;
  overflow: auto;

  @include mqMaxWidth($screenS) {
    height: calc(100% - 120px);
  }
}

.tableContainer {
  height: 100% !important;
  padding-bottom: 139px;
  position: relative;

  table {
    height: 100%;
    background-color: #fff;

    tbody {
      background-color: #fff;

      tr {
        td {
          color: $titleColor;
          white-space: nowrap;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }
}

.leiCell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .to {
    padding-left: 10px;
    color: $secondary2Color;
  }

  @include mqMaxWidth($screenS) {
    .leiName {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 240px;
      vertical-align: middle;
    }
  }
}
