@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tooltipIcon {
  margin-left: 4px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondary2Color;
    position: relative;
    top: 2px;

    svg {
      vertical-align: top;
    }

    &:hover {
      fill: $titleColor;
    }
  }
}
