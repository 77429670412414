@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cardHeader {
  @include mqMaxWidth($screenXs) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      &:last-child {
        width: auto;
      }
    }
  }
}

.horizontalScrollContainer {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

.tablesScrollWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: fit-content;
}

.headerTable {
  margin-bottom: 15px;
  margin-top: 20px;

  thead {
    tr {
      border: 0;

      th {
        border: 0;
        padding-top: 0;
        padding-bottom: 10px;
        line-height: 12px;
      }
    }
  }

  tbody {
    tr {
      border: 0;

      td {
        color: $titleColor;
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }
}

.dataTable {
  tbody {
    tr {
      td {
        color: $titleColor;
        white-space: nowrap;
      }
    }
  }
}
