@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.submitButton {
  margin-right: 30px;
}

.text {
  margin-top: 0;
}

.input {
  margin-bottom: 10px;
}

.inputWrapper {
  position: relative;
}

.error {
  font-size: 11px;
  line-height: 15px;
  color: $cancelColor;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
}
