@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.subtitle {
  font-size: 11px;
  font-weight: 400;
  line-height: 10px;
  color: $secondary3Color;
  text-transform: none;
  margin-left: 10px;
  pointer-events: none;
}

.radioGroup {
  display: flex;
  flex-direction: column;

  > label {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
