@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @include mqMaxWidth($screenXs) {
    flex-wrap: wrap;
  }
}

.headerTitleDesktop {
  @include mqMaxWidth($screenXs) {
    display: none;
  }
}

.headerTitle {
  word-break: break-word;
}

.headerTitleMobile {
  display: none;
  margin-bottom: 0;

  @include mqMaxWidth($screenXs) {
    display: block;
  }
}

.pageNavigationLinks {
  max-width: 100%;
  flex: 1;
  overflow: auto;
  padding: 16px 0;

  @include mqMaxWidth($screenXs) {
    order: 1;
    overflow: visible;
    margin-top: 10px;
    padding: 0;
  }

  > div {
    padding-top: 0;
    overflow: visible;

    @include mqMaxWidth($screenXs) {
      overflow: auto;
      padding: 10px 0;
    }
  }
}

.actions {
  display: flex;
  align-items: center;

  @include mqMaxWidth($screenXs) {
    margin-left: 20px;
  }
}

.actionsWrapper {
  margin-left: 35px;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
  }
}
