@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.subtitle {
  font-size: 11px;
  font-weight: 400;
  line-height: 10px;
  color: $secondary3Color;
  text-transform: none;
  margin-left: 10px;
}

.fieldLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $secondary3Color;
  padding-top: 30px;
  padding-bottom: 15px;

  &.firstField {
    padding-top: 0;
  }
}

.upsizedField {
  margin-top: 10px;
}

.disabled {
  color: #ccc;
}
