@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.card {
  @include mqMaxWidth($screen568) {
    margin-bottom: 30px;
  }
}

.formInputWrp {
  width: 100%;
  max-width: 495px;
}

.formInputWrpSmall {
  max-width: 242px;
  float: left;

  @include mqMaxWidth($screen568) {
    max-width: 495px;
  }
}

.formInputWrpSmallMargin {
  margin-right: 10px;
}
